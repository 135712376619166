import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';

interface Message {
  text: string;
  isUser: boolean;
}

interface Translation {
  japanese: string;
  english: string;
  rowIndex: number;
  flag?: string;
}

const SPREADSHEET_ID = '11oT5AFb1y_q6Qs28Hg61TMk9gS7x_LUVHItLEAnbejw';
const CLIENT_ID = '368174254241-g40088pq6lg6vk6egoo6lf3jgslsdcu4.apps.googleusercontent.com';
//const REDIRECT_URI = 'http://localhost:3000'; // あなたのアプリケーションのコールバックURIに変更してください
const REDIRECT_URI = 'https://dp-xr-42ec4.web.app'; // あなたのアプリケーションのコールバックURIに変更してください
const SCOPES = [
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.readonly',
  'https://www.googleapis.com/auth/spreadsheets',
  'https://www.googleapis.com/auth/spreadsheets.readonly'
];

const App: React.FC = () => {
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [userInput, setUserInput] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attemptCount, setAttemptCount] = useState<number>(0);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // URLからアクセストークンを取得
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const token = params.get('access_token');

    if (token) {
      setAccessToken(token);
      window.history.pushState("", document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchTranslations();
    }
  }, [accessToken]);

  useEffect(() => {
    if (translations.length > 0 && !isLoading) {
      addMessage(translations[currentIndex].japanese, false);
      setAttemptCount(0);
    }
  }, [currentIndex, translations, isLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const fetchTranslations = async () => {
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/A:C`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      
      const records = response.data.values as string[][];
      
      const newTranslations = records.slice(1)
        .map((record: string[], index: number) => ({
          japanese: record[0],
          english: record[1],
          rowIndex: index + 2, // スプレッドシートの実際の行番号
          flag: record[2]
        }))
        .filter(translation => 
          translation.japanese && 
          translation.english && 
          translation.flag === undefined
        );
      
      setTranslations(newTranslations);
      setCurrentIndex(0);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching translations:', error);
      setIsLoading(false);
    }
  };

  const addMessage = (text: string, isUser: boolean) => {
    setMessages(prev => [...prev, { text, isUser }]);
  };

  const updateSpreadsheet = async (rowIndex: number, columnC: string, columnD: string) => {
    try {
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/C${rowIndex}:D${rowIndex}?valueInputOption=USER_ENTERED`,
        {
          values: [[columnC === '1' ? '1' : '', columnD]]
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error updating spreadsheet:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    addMessage(userInput, true);
    setAttemptCount(prev => prev + 1);

    const currentTranslation = translations[currentIndex];

    if (userInput.toLowerCase().trim() === currentTranslation.english.toLowerCase()) {
      addMessage("正解です！次の問題に進みましょう。", false);

      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/D${currentTranslation.rowIndex}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const currentDValue = response.data.values ? Number(response.data.values[0][0]) : 0;

      let newCValue = '';
      if ((attemptCount === 0 && (currentDValue === 0 || currentDValue === undefined)) ||
          (attemptCount <= 1 && currentDValue >= 1)) {
        newCValue = '1';
      }

      const newDValue = currentDValue + attemptCount + 1;

      await updateSpreadsheet(currentTranslation.rowIndex, newCValue, newDValue.toString());

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % translations.length);
      }, 1500);
    } else {
      addMessage(`不正解です。正解は: ${currentTranslation.english}`, false);
    }

    setUserInput('');
  };

  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&` +
      `response_type=token&` +
      `scope=${encodeURIComponent(SCOPES.join(' '))}&` +
      `access_type=online&` +
      `prompt=select_account&` +
      `include_granted_scopes=true&` +
      `login_hint=&` +
      `state=${encodeURIComponent(JSON.stringify({ from: 'login' }))}`;
  
    window.location.href = authUrl;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\u2019/g, "'");//What’s up?
    setUserInput(value);
  };

  if (!accessToken) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>英語翻訳チャットクイズ</h1>
        </header>
        <button onClick={handleLogin}>Google アカウントを選択してログイン</button>
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>英語翻訳チャットクイズ</h1>
      </header>
      <div className="chat-container" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isUser ? 'message-user' : 'message-system'}`}>
            <span className="message-content">
              {message.text}
            </span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="英語で入力してください"
          className="input-field"
        />
        <button type="submit" className="submit-button">送信</button>
      </form>
    </div>
  );
};

export default App;